<template>
	<Indecision />
</template>

<script>
	// import Counter from "@/components/Counter.vue";
	import Indecision from "@/components/Indecision.vue";

	export default {
		name: "App",
		components: {
			// appCounter: Counter,
			Indecision,
		},
	};
</script>
<style>
	#app {
		font-family: Avenit, Arial, Helvetica, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		text-align: center;
		color: #2c3e50;
		margin-top: 60px;
	}
</style>

