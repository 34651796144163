<template>
	<div class="container">
		<h1>Indecision</h1>
		<img :src="img" alt="bg" />
		<div class="bg-dark"></div>
		<div class="indecision-container">
			<input type="text" placeholder="Hazme una pregunta" v-model="question" />
			<p>Recuerda terminar con un signo de interogacion (?)</p>
			<div v-show="isValidQuestion">
				<h2>{{ question }}</h2>
				<h1>{{ answer }}</h1>
			</div>
		</div>
	</div>
</template>
<script>
	export default {
		name: "Indecision",
		data() {
			return {
				title: "Indecision",
				question: "",
				answer: null,
				img: "",
				isValidQuestion: false,
			};
		},
		methods: {
			async getAnswer() {
				this.answer = "Pensando....";
				const { image, answer } = await fetch("https://yesno.wtf/api").then((r) =>
					r.json()
				);
				this.img = image;
				if (answer === "yes") {
					this.answer = "Si";
				} else if (answer === "no") {
					this.answer = "No!!!";
				} else if (answer === "maybe") {
					this.answer = "Tal vez";
				}
				setTimeout(() => {
					this.question = "";
				}, 5000);
				// console.log(answer);
			},
		},
		watch: {
			question(value, oldValue) {
				this.isValidQuestion = false;
				if (!value.includes("?")) return;
				this.isValidQuestion = true;
				this.getAnswer();
			},
		},
	};
</script>
<style scoped>
	img,
	.bg-dark {
		height: 100vh;
		left: 0px;
		max-height: 100%;
		max-width: 100%;
		position: fixed;
		top: 0px;
		width: 100vw;
	}

	.bg-dark {
		background-color: rgba(0, 0, 0, 0.4);
	}

	.indecision-container {
		position: relative;
		z-index: 99;
	}

	input {
		width: 250px;
		padding: 10px 15px;
		border-radius: 5px;
		border: none;
	}
	input:focus {
		outline: none;
	}

	p {
		color: white;
		font-size: 20px;
		margin-top: 0px;
	}

	h1,
	h2 {
		color: white;
	}

	h2 {
		margin-top: 150px;
	}
</style>
